import React from "react";
import Game from "../atoms/game/game";
import Layout from "../atoms/layout/layout";
import "./home.scss";

export default function Home() {
  return (
    <Layout noMargin>
      <Game></Game>
    </Layout>
  );
}
