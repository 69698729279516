import React, { Suspense } from "react";
import "./App.scss";
import { Router } from "@reach/router";
import Home from "./screens/home";

//2

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Home path="/"></Home>
      </Router>
    </Suspense>
  );
}

export default App;
