import { Canvas, useFrame } from "@react-three/fiber";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./game.scss";
import * as THREE from "three";

import socketIOClient from "socket.io-client";
const ENDPOINT = "http://127.0.0.1:4001";
let socket = socketIOClient(ENDPOINT);
function useComms() {
  let data = useMemo(
    () => ({
      inputs: {
        left: false,
        right: false,
        up: false,
        down: false,
      },
    }),
    []
  );
  useEffect(() => {});

  const setInputs = (d) => {
    if (socket) {
      d = { ...data.inputs, ...d };
      if (
        data.inputs.left !== d.left ||
        data.inputs.right !== d.right ||
        data.inputs.up !== d.up ||
        data.inputs.down !== d.down
      ) {
        data.inputs = d;
        socket.emit("setInputs", data.inputs);
      }
    }
  };

  return { setInputs };
}
function useSocket() {
  console.log("use_socket");
  let [players, setPlayers] = useState([]);
  let data = useMemo(
    () => ({
      gamestates: [],
      gamestate: null,
    }),
    []
  );
  useEffect(() => {
    socket.emit("wakeup");
    console.log("effect");
    socket.on("players", (d) => {
      setPlayers(d);
    });
    socket.on("welcome", (resp) => {
      console.log("welcome", resp);
      socket.on("gamestate", (resp) => {
        resp.time = Date.now();
        data.gamestates.unshift(resp);
        if (data.gamestates.length > 20) {
          data.gamestates.length = 20;
        }
        //console.log(data.gamestates);
        if (!data.gamestate) {
          data.gamestate = resp;
        }
      });
    });
    return;
  }, [data]);
  const getGameState = () => {
    return data.gamestate;
  };

  const updateGameState = () => {
    //uncomment this to disable interpolation
    /*
    data.gamestate = data.gamestates[0];
    return;
    */
    let lag = 400;
    let time = Date.now() - lag;
    //find 2 frames I am within:
    let before = null;
    let after = null;

    for (let i = 0; i < data.gamestates.length; i++) {
      let gs = data.gamestates[i];
      if (gs.time > time) {
        //console.log("set after");
        after = gs;
      }
      if (gs.time < time && !before) {
        //console.log("set before");
        before = gs;
      }
    }
    if (before && after) {
      let diff = after.time - before.time;
      let ownDiff = after.time - time;
      let percentage = 1 - ownDiff / diff;

      let newGamestate = {
        ...after,
        players: { ...after.players },
      };

      Object.values(newGamestate.players).forEach((p) => {
        let oldPos = before.players[p.id]
          ? before.players[p.id].position
          : null;
        //console.log(oldPos, p.position);
        if (!oldPos) {
          //console.log("short");
          return;
        }

        newGamestate.players[p.id] = {
          ...p,
          position: {
            x: oldPos.x * (1 - percentage) + p.position.x * percentage,
            y: oldPos.y * (1 - percentage) + p.position.y * percentage,
            z: oldPos.z * (1 - percentage) + p.position.z * percentage,
          },
        };
      });
      data.gamestate = newGamestate;
    } else {
      //console.log("fuck");
    }

    //do lerp.
  };
  return { getGameState, updateGameState, players };
}

export default function Game() {
  let { setInputs } = useComms();
  const keyDown = useCallback(
    (e) => {
      console.log(e.key);
      if (e.key === "a" || e.key === "ArrowLeft") {
        setInputs({ left: true });
      }
      if (e.key === "d" || e.key === "ArrowRight") {
        setInputs({ right: true });
      }
      if (e.key === "w" || e.key === "ArrowUp") {
        setInputs({ up: true });
      }
      if (e.key === "s" || e.key === "ArrowDown") {
        setInputs({ down: true });
      }
    },
    [setInputs]
  );
  const keyUp = useCallback(
    (e) => {
      if (e.key === "a" || e.key === "ArrowLeft") {
        setInputs({ left: false });
      }
      if (e.key === "d" || e.key === "ArrowRight") {
        setInputs({ right: false });
      }
      if (e.key === "w" || e.key === "ArrowUp") {
        setInputs({ up: false });
      }
      if (e.key === "s" || e.key === "ArrowDown") {
        setInputs({ down: false });
      }
    },
    [setInputs]
  );
  useEffect(() => {
    document.addEventListener("keydown", keyDown);
    document.addEventListener("keyup", keyUp);

    return () => {
      document.removeEventListener("keydown", keyDown);
      document.removeEventListener("keyup", keyUp);
    };
  }, [keyDown, keyUp]);
  return (
    <Canvas>
      <directionalLight></directionalLight>
      <ambientLight></ambientLight>
      <pointLight position={[150, 150, 150]} intensity={0.55} />
      <Boxes></Boxes>
    </Canvas>
  );
}

const tempObject = new THREE.Object3D();
const tempColor = new THREE.Color();

function Boxes() {
  const colorArray = useMemo(
    () =>
      Float32Array.from(
        new Array(100).fill().flatMap((_, i) => tempColor.set("blue").toArray())
      ),
    []
  );
  let { getGameState, updateGameState, players } = useSocket();
  console.log(players);
  const meshRef = useRef();
  useFrame((state) => {
    //meshRef.current.rotation.x = Math.sin(time / 4);
    //meshRef.current.rotation.y = Math.sin(time / 2);
    updateGameState();
    let gs = getGameState();
    if (gs) {
      for (let id = 0; id < 100; id++) {
        let p = players[id];
        if (p) {
          p = gs.players[p.id];
        }
        if (p) {
          tempObject.position.set(p.position.x, p.position.y, p.position.z);
          //tempColor.set(id === hovered ? 'white' : colors[id]).toArray(colorArray, id * 3)

          //tempObject.rotation.z = tempObject.rotation.y * 2;

          //tempObject.scale.set(scale, scale, scale)
          tempObject.updateMatrix();
          meshRef.current.setMatrixAt(id, tempObject.matrix);
        } else {
        }
      }
      meshRef.current.instanceMatrix.needsUpdate = true;
    }
  });
  return (
    <instancedMesh ref={meshRef} args={[null, null, 100]}>
      <boxGeometry args={[0.7, 0.7, 0.7]}>
        <instancedBufferAttribute
          attachObject={["attributes", "color"]}
          args={[colorArray, 3]}
        />
      </boxGeometry>

      <meshPhongMaterial vertexColors={THREE.VertexColors} />
    </instancedMesh>
  );
}
