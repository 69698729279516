import React from "react";
import "./layout.scss";

export default function Layout({ children, noMargin, style }) {
  return (
    <div className={"inner " + (noMargin ? "noMargin " : " ")} style={style}>
      {children}
    </div>
  );
}
